import React from 'react'
import Helmet from 'react-helmet'

import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import Heading from 'components/Heading'

const Lab = () => (
  <>
    <Helmet title="The Lab | François Deguire" />
    <Section>
      <Wrapper>
        <Heading
          css={`
            color: #fe9933;
          `}
        >
          Under construction. <br />
          Check back soon
        </Heading>
      </Wrapper>
    </Section>
  </>
)

export default Lab
