import React from 'react'
import { node, bool } from 'prop-types'
import styled, { css } from 'styled-components'

import { media } from 'styles/media'
import { verticalPadding } from 'styles/helpers'

const Container = styled.section`
  display: block;
  ${verticalPadding`64px`};

  ${props =>
    props.flushTop &&
    css`
      padding-top: 0 !important;
    `}

  ${media.small`
    ${verticalPadding`80px`};
  `};

  ${media.medium`
    ${verticalPadding`100px`};
  `};

  ${media.large`
    ${verticalPadding`128px`};
  `};

  ${media.xLarge`
    ${verticalPadding`10vw`};
  `};
`

const Block = styled.div`
  position: relative;

  & + & {
    margin-top: 40px;

    ${media.small`
      margin-top: 56px;
    `};

    ${media.medium`
      margin-top: 72px;
    `};

    ${media.large`
      margin-top: 80px;
    `};

    ${media.xLarge`
      margin-top: 96px;
    `};
  }
`

const Section = ({ children, flushTop }) => (
  <Container flushTop={flushTop}>{children}</Container>
)

Section.propTypes = {
  children: node.isRequired,
  flushTop: bool,
}

export const SectionBlock = ({ children }) => <Block>{children}</Block>

SectionBlock.propTypes = {
  children: node.isRequired,
}

export default Section
